.Modal-column {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: space-evenly;
}

.Modal-button {
  background-color: transparent;
  margin: 2px 0px;
}

.Modal-button:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.Modal-textarea {
  position: relative;
  resize: vertical;
}

html {
  overflow-x: hidden;
}
